define("discourse/plugins/discourse-user-feedback/discourse/components/modal/dispute-feedback", ["exports", "@ember/component", "discourse/lib/ajax-error", "discourse/lib/ajax", "discourse-common/utils/decorators", "@ember/object", "@ember/template-factory"], function (_exports, _component, _ajaxError, _ajax, _decorators, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="dispute-feedback"
    id="dispute-feedback"
    @closeModal={{@closeModal}}
  >
    <:body>
      <p>{{{i18n "user_feedback.enter_dispute_reason"}}}</p>
  
      <Textarea
        @value={{this.raw}}
        rows="5"
        class="rating-textarea"
      />
    </:body>
    <:footer>
      <DButton
        class="btn-danger btn-large"
        @action={{action "dispute"}}
        @disabled={{this.disabled}}
        @label="user_feedback.confirm"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "H2D8Lfz/",
    "block": "[[[8,[39,0],[[24,0,\"dispute-feedback\"],[24,1,\"dispute-feedback\"]],[[\"@closeModal\"],[[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[2,[28,[37,1],[\"user_feedback.enter_dispute_reason\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,2],[[24,\"rows\",\"5\"],[24,0,\"rating-textarea\"]],[[\"@value\"],[[30,0,[\"raw\"]]]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-danger btn-large\"]],[[\"@action\",\"@disabled\",\"@label\"],[[28,[37,4],[[30,0],\"dispute\"],null],[30,0,[\"disabled\"]],\"user_feedback.confirm\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"textarea\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-user-feedback/discourse/components/modal/dispute-feedback.hbs",
    "isStrictMode": false
  });
  let DisputeFeedback = _exports.default = (_dec = (0, _decorators.default)("loading", "raw"), (_class = class DisputeFeedback extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "loading", false);
      _defineProperty(this, "feedback", null);
      _defineProperty(this, "raw", "");
    }
    dispute() {
      this.set("loading", true);
      (0, _ajax.ajax)(`/user-feedback/u/${this.model.user.id}/${this.model.feedback.id}/dispute`, {
        type: "POST",
        data: {
          raw: this.raw
        }
      }).then(() => {
        this.model.feedback.set("disputed", true);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.closeModal();
      });
    }
    disabled(loading, raw) {
      if (loading) return true;
      if (raw.trim().length < 1) return true;
      return false;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "dispute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dispute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype)), _class));
  ;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DisputeFeedback);
});