define("discourse/plugins/discourse-user-feedback/discourse/controllers/user-activity-feedback", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    currentUserWatching: function () {
      let user = this.get('user');
      let currentUser = Discourse.User.current();
      return currentUser !== null && currentUser.id === user.id;
    }.property('model'),
    guestUserWatching: function () {
      let currentUser = Discourse.User.current();
      return currentUser === null;
    }.property()
  });
});