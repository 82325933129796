define("discourse/plugins/discourse-user-feedback/discourse/components/rating-composer", ["exports", "discourse/models/user-action", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "@ember/component", "@ember/array"], function (_exports, _userAction, _ajax, _decorators, _ajaxError, _component, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj; // import { emojiUnescape } from 'discourse/lib/text';
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("loading", "rating", "raw", "postedBy", "stream.user.feedback_limited"), _dec2 = (0, _decorators.default)("isSeller", "isBuyer"), _dec3 = (0, _decorators.observes)("isSeller"), _dec4 = (0, _decorators.observes)("isBuyer"), (_obj = {
    rating: 0,
    raw: "",
    isSeller: false,
    isBuyer: false,
    actions: {
      save() {
        if (this.disabled) return;
        this.set("loading", true);
        const stream = this.get("stream");
        const user = stream.get("user");
        (0, _ajax.ajax)(`/user-feedback/u/${user.id}.json`, {
          type: "POST",
          data: {
            raw: this.raw,
            rating: this.rating,
            posted_by: this.postedBy
          }
        }).then(data => {
          const action = data.rating;
          const copy = (0, _array.A)();
          copy.pushObject(_userAction.default.create(action));
          stream.get("content").insertAt(0, _userAction.default.collapseStream(copy)[0]);
          stream.set("itemsLoaded", stream.get("itemsLoaded") + 1);
          this.setProperties({
            rating: 0,
            raw: "",
            isBuyer: false,
            isSeller: false
          });
          user.setProperties({
            average_rating: data.average_rating,
            ratings_count: data.ratings_count,
            feedback_limited: data.feedback_limited
          });
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("loading", false);
        });
      }
    },
    disabled(loading, rating, raw, postedBy, limited) {
      if (limited) return true;
      if (loading) return true;
      if (rating < 1) return true;
      if (raw.trim().length < 1) return true;
      if (!postedBy) return true;
      return false;
    },
    postedBy(isSeller, isBuyer) {
      if (isSeller) return "seller";
      if (isBuyer) return "buyer";
      return null;
    },
    _nullBuyer() {
      if (this.isSeller) {
        this.set("isBuyer", false);
        this.notifyPropertyChange("isBuyer");
      }
    },
    _nullSeller() {
      if (this.isBuyer) {
        this.set("isSeller", false);
        this.notifyPropertyChange("isSeller");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "disabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "disabled"), _obj), _applyDecoratedDescriptor(_obj, "postedBy", [_dec2], Object.getOwnPropertyDescriptor(_obj, "postedBy"), _obj), _applyDecoratedDescriptor(_obj, "_nullBuyer", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_nullBuyer"), _obj), _applyDecoratedDescriptor(_obj, "_nullSeller", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_nullSeller"), _obj)), _obj)));
});