define("discourse/plugins/discourse-user-feedback/discourse/components/feedback-stream-item", ["exports", "discourse/components/user-stream-item", "discourse-common/utils/decorators"], function (_exports, _userStreamItem, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _userStreamItem.default.extend((_dec = (0, _decorators.default)("item.disputed", "currentUser.id", "user.id"), _dec2 = (0, _decorators.default)("item.disputed", "currentUser.staff"), (_obj = {
    classNameBindings: ["item.scoped:scoped"],
    canDispute(disputed, currentUserId, userId) {
      return !disputed && currentUserId && currentUserId === userId;
    },
    canClearDispute(disputed, isStaff) {
      return disputed && isStaff;
    }
  }, (_applyDecoratedDescriptor(_obj, "canDispute", [_dec], Object.getOwnPropertyDescriptor(_obj, "canDispute"), _obj), _applyDecoratedDescriptor(_obj, "canClearDispute", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canClearDispute"), _obj)), _obj)));
});