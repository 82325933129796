define("discourse/plugins/discourse-user-feedback/discourse/routes/user-feedback", ["exports", "discourse/plugins/discourse-user-feedback/discourse/models/feedback", "discourse/lib/url", "@ember/runloop", "@ember/routing/route"], function (_exports, _feedback, _url, _runloop, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    queryParams: {
      posted_by: {
        refreshModel: true
      },
      scoped: {
        refreshModel: true
      }
    },
    user() {
      return this.modelFor("user");
    },
    beforeModel(transition) {
      if (!this.siteSettings.user_feedback_enabled) {
        this.transitionTo("userActivity", this.user());
      }
    },
    model(params) {
      this.set("scoped", parseInt(params.scoped));
      return _feedback.default.create({
        user: this.user(),
        scoped: params.scoped || "",
        postedBy: params.posted_by || ""
      });
    },
    afterModel(model) {
      return model.filterBy({}).then(() => model.set("scoped", ""));
    },
    setupController(controller, model) {
      const scoped = this.scoped;
      if (scoped) {
        const post = model.content.findBy("id", scoped);
        if (post) {
          post.set("scoped", true);
          model.content.removeObject(post);
          model.content.insertAt(0, post);
        }
      }
      this._super(...arguments);
      if (scoped) {
        (0, _runloop.next)(this, () => {
          const path = location.protocol + "//" + location.host + location.pathname;
          _url.default.replaceState(path);
        });
      }
    }
  });
});