define("discourse/plugins/discourse-user-feedback/discourse/components/feedback-stream", ["exports", "discourse/components/user-stream", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service", "@ember/application", "discourse/plugins/discourse-user-feedback/discourse/components/modal/dispute-feedback"], function (_exports, _userStream, _ajax, _ajaxError, _service, _application, _disputeFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _userStream.default.extend({
    isLoading: false,
    dialog: (0, _service.inject)(),
    actions: {
      deleteFeedback(id) {
        this.dialog.confirm({
          message: I18n.t("user_feedback.confirm_delete"),
          confirmButtonClass: 'btn-danger',
          didConfirm: () => {
            this.deleteFeedback(id);
          },
          didCancel: () => {
            return;
          }
        });
      },
      disputeFeedback(feedback) {
        let modal = (0, _application.getOwner)(this).lookup("service:modal");
        const result = modal.show(_disputeFeedback.default, {
          model: {
            user: this.stream.user,
            raw: "",
            feedback: feedback
          }
        });
        modal.setProperties({
          loading: this.get("isLoading"),
          feedback,
          user: this.stream.user,
          raw: ""
        });
      },
      clearDispute(feedback) {
        this.dialog.confirm({
          message: I18n.t("user_feedback.confirm_clear_dispute"),
          confirmButtonClass: 'btn-danger',
          didConfirm: () => {
            this.clearDispute(feedback);
          },
          didCancel: () => {
            return;
          }
        });
      }
    },
    deleteFeedback(id) {
      this.set("isLoading", true);
      const stream = this.get("stream");
      (0, _ajax.ajax)(`/user-feedback/u/${stream.user.id}/${id}.json`, {
        type: "DELETE"
      }).then(data => {
        const action = stream.content.findBy("id", id);
        if (action) {
          stream.content.removeObject(action);
          stream.set("itemsLoaded", stream.itemsLoaded - 1);
        }
        stream.user.setProperties({
          average_rating: data.average_rating,
          ratings_count: data.ratings_count
        });
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("isLoading", false));
    },
    clearDispute(feedback) {
      this.set("isLoading", true);
      const stream = this.get("stream");
      (0, _ajax.ajax)(`/user-feedback/u/${stream.user.id}/${feedback.id}/clear`, {
        type: "POST"
      }).then(data => {
        feedback.set("disputed", false);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("isLoading", false));
    }
  });
});