define("discourse/plugins/discourse-user-feedback/discourse/controllers/user-feedback", ["exports", "discourse-common/utils/decorators", "@ember/controller"], function (_exports, _decorators, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("currentUser.id", "model.user.id"), (_obj = {
    queryParams: ["scoped", "posted_by"],
    scoped: null,
    posted_by: null,
    canCreateFeedback(currentUserId, userId) {
      return currentUserId && currentUserId !== userId;
    },
    actions: {
      toggleFilter(filter, val) {
        const newVal = this.get(filter) === val ? null : val;
        const newProps = {
          scoped: null
        };
        newProps[filter] = newVal;
        this.setProperties(newProps);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "canCreateFeedback", [_dec], Object.getOwnPropertyDescriptor(_obj, "canCreateFeedback"), _obj)), _obj)));
});