define("discourse/plugins/discourse-user-feedback/discourse/models/ratings-stream", ["exports", "discourse/models/user-stream", "discourse/lib/computed", "discourse/lib/ajax", "discourse/models/user-action", "discourse/lib/text", "rsvp"], function (_exports, _userStream, _computed, _ajax, _userAction, _text, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _userStream.default.extend({
    baseUrl: (0, _computed.url)('user.id', 'itemsLoaded', '/user-feedback/u/%@.json?offset=%@'),
    findItems() {
      const self = this;
      let findUrl = this.get('baseUrl');
      if (this.get('noContentHelpKey')) {
        findUrl += "&no_results_help_key=" + this.get('noContentHelpKey');
      }

      // Don't load the same stream twice. We're probably at the end.
      const lastLoadedUrl = this.get('lastLoadedUrl');
      if (lastLoadedUrl === findUrl) {
        return (0, _rsvp.resolve)();
      }
      if (this.get('loading')) {
        return (0, _rsvp.resolve)();
      }
      this.set('loading', true);
      return (0, _ajax.ajax)(findUrl, {
        cache: 'false'
      }).then(function (result) {
        if (result && result.no_results_help) {
          self.set('noContentHelp', result.no_results_help);
        }
        if (result && result.user_actions) {
          const copy = Em.A();
          result.user_actions.forEach(function (action) {
            action.title = (0, _text.emojiUnescape)(Handlebars.Utils.escapeExpression(action.title));
            copy.pushObject(_userAction.default.create(action));
          });
          self.get('content').pushObjects(_userAction.default.collapseStream(copy));
          self.setProperties({
            itemsLoaded: self.get('itemsLoaded') + result.user_actions.length
          });
        }
      }).finally(function () {
        self.set('loaded', true);
        self.set('loading', false);
        self.set('lastLoadedUrl', findUrl);
      });
    }
  });
});