define("discourse/plugins/discourse-user-feedback/discourse/components/rating-stars", ["exports", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj, _init;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("rating"), (_obj = {
    starsNumber: 5,
    readOnly: true,
    rating: 0,
    didReceiveAttrs() {
      this._super(...arguments);
      const stars = [];
      for (let i = 0; i < this.get("starsNumber"); i++) {
        let star = {
          full: i < this.get("rating")
        };
        stars.push(star);
      }
      this.set("stars", stars);
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.get("readOnly") === false) {
        $(this.element).find(".rating-stars").css("cursor", "pointer");
      }
    },
    click(event) {
      if (this.get("readOnly")) {
        return;
      }
      let rating = this.getTarget(event.pageX);
      if (rating <= this.get("starsNumber")) {
        this.set("rating", rating);
        if (this.get("action") !== null) {
          this.get("action")(rating);
        }
      }
    },
    updateStars: function () {
      let rating = this.get("rating");
      let stars = $(this.element).find(".star-icon");
      stars.each(function (index, element) {
        if (index < rating) {
          $(element).addClass("full");
        } else {
          $(element).removeClass("full");
        }
      });
    },
    getTarget(x) {
      const starsNumber = this.get("starsNumber");
      return Math.ceil(starsNumber * (x - $(this.element).find(".rating-stars").offset().left) / $(this.element).find(".rating-stars").width());
    }
  }, (_applyDecoratedDescriptor(_obj, "updateStars", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "updateStars"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj)), _obj)));
});